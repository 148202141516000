import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import "./../styles/AboutUs.css";
import Image1 from "./../assets/images/a798d24301467b1974e51cece948c71b.png"
import Image2 from "./../assets/images/e91f591b2be3b8794ee1b5a2e02f90c7.png"
import Image3 from "./../assets/images/47608a534a04c22b2b11a1aed372ffc0.png"
import Image4 from "./../assets/images/9dff9bb55d7fb1510e3451c2d943be0a.png"
import React, { useEffect, useState } from "react";



export default function AboutUs() {

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);


  return (
    <>
      <Navigation />
      <section className="AsectionOne lg:lg_screenp md:md_screenp">
        <div className={`flex gap-10 w-full pt-16 items-center ${mounted? 'animate_from_left' : ''}`}>
          <h1 className="text-2xl font-semibold text_primary lg:font32px md:text-2xl whitespace-nowrap">About Us</h1>
          <hr className="header_hr" />
        </div>
        <p className={`text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base ${mounted ? 'animate_stats' : ''}`}>
          Explore the journey behind Tradegenie and our commitment to
          revolutionizing the world of finance through blockchain technology.
        </p>
      </section>
      <section className="AsectionTwo grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 lg:lg_screenp md:md_screenp">
        <div className={`${mounted ? 'animate_from_left' : ''}`}>
          <img src={Image1} alt="" className="hover:animate-bounce"/>
          <h1 className="text-2xl font-semibold text_primary lg:font32px md:text-2xl mt-5">Our Mission</h1>
          <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
            Tradegenie utilizes the fundamental concept of blockchain technology
            – decentralization to connect buyers and sellers while prioritizing
            security, transaction speed and quick dispute resolution.
          </p>
        </div>
        <div className={`${mounted ? 'animate_stats' : ''}`}>
          <img src={Image2} alt=""  className="max-sm:mt-5 hover:animate-bounce"/>
          <h1 className="text-2xl font-semibold text_primary lg:font32px md:text-2xl mt-5">Our Objectives</h1>
          <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
            Tradegenie utilizes the fundamental concept of blockchain technology
            – decentralization to connect buyers and sellers while prioritizing
            security, transaction speed and quick dispute resolution.
          </p>
        </div>
        <div className={`mt-8 max-sm:mt-5 ${mounted ? 'animate_from_left' : ''}`}>
          <img src={Image2} alt="" className="hover:animate-bounce"/>
          <h1 className="text-2xl font-semibold text_primary lg:font32px md:text-2xl mt-5">Our Value</h1>
          <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
            We are committed to delivering exceptional customer service,
            transparency and reliability. We value innovation, collaboration,
            and strive to build a culture that fosters diversity and
            inclusivity.
          </p>
        </div>
        <div className={`mt-8 ${mounted? 'animate_stats' : ''}`}>
          <img src={Image1} alt="" className="hover:animate-bounce" />
          <h1 className="text-2xl font-semibold text_primary lg:font32px md:text-2xl mt-5">Our Goals</h1>
          <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
            We are committed to delivering exceptional customer service,
            transparency and reliability. We value innovation, collaboration,
            and strive to build a culture that fosters diversity and
            inclusivity.
          </p>
        </div>
      </section>

      <section className="AsectionOne lg:lg_screenp md:md_screenp">
        <div className={`flex gap-10 w-full items-center ${mounted? 'animate_from_left' : ''}`}>
          <h1 className="text-2xl font-semibold text_primary lg:font32px md:text-2xl whitespace-nowrap">Why choose Tradegenie?</h1>
          <hr className="header_hr" />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 mt-8">
          <div className={`mt-5 ${mounted ? 'animate_from_left' : ''}`}>
            <img src={Image4} alt="" className="hover:animate-bounce"/>
            <p className="whychoose_p mt-5">Swift Transactions</p>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
              Buy and sell cryptocurrency swiftly at the best rates.{" "}
            </p>
          </div>
          <div className={`mt-5 ${mounted ?  'animate_stats' : ''}`}>
            <img src={Image3} alt="" className="hover:animate-bounce"/>
            <p className="whychoose_p mt-5">Quick Dispute Resolution</p>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
              24/7 customer service to handle transaction disputes and resolve
              quickly.{" "}
            </p>
          </div>
          <div className={`${mounted ? 'animate_stats' : ''}`}>
            <img src={Image1} alt="" className="mt-5 hover:animate-bounce"/>
            <p className="whychoose_p mt-5">Comprehensive Services</p>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
              Tradegenie provides diverse financial solutions, from crypto
              trading to cross-border payments, tailored to meet your individual
              and business needs.
            </p>
          </div>
        </div>
      </section>


      <Footer/>
    </>
  );
}
