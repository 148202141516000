import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import "./../styles/Services.css";
import Faq from "./../components/Faq"
import Image1 from "./../assets/images/a6f2dfd9392c0ba240f395caac7bf744.png"
import Image2 from "./../assets/images/855ddc6fc2fa831cfd070c3a01074346.png"
import React, { useEffect, useState } from "react";
import RateCalculator from "../components/RateCalculator";

export default function Services() {

    const [mounted, setMounted] = useState(false);
    useEffect(() => {
      setMounted(true);
    }, []);

  return (
    <>
      <Navigation />
      <section className="SsectionOne lg:lg_screenp md:md_screenp">
        <div className={`flex gap-10 w-full items-center pt-16 ${mounted? 'animate_from_left' : ''}`}>
          <h1 className="text-2xl font-semibold text_primary lg:font32px  md:text-2xl"> Services</h1>
          <hr className="header_hr" />
        </div>
        <p className={`text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base ${mounted? 'animate_stats' : ''}`}>
          Explore the journey behind Tradegenie and our commitment to
          revolutionizing the world of finance through blockchain technology.
        </p>
      </section>

      <section className="SsectionTwo lg:lg_screenp md:md_screenp grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 ${mounted? 'animate_from_left' : ''}`}>
          <div>
            <img src={Image1} alt="" />
            <h1 className="text-2xl font-semibold text_primary mt-5 lg:font32px md:text-2xl">Peer-to-Peer Services</h1>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base ">
              Tradegenie facilitates the buying and selling of multiple
              cryptocurrencies through its peer-to-peer network.
            </p>
          </div>
          <div className="mt-8">
            <img src={Image2} alt="" />
            <h1 className="text-2xl font-semibold text_primary mt-5 lg:font32px md:text-2xl">Cross-Border Payments</h1>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base ">
              Tradegenie aids small-scale and large-scale companies to carry out
              transactions worldwide keeping swift transactions and good market
              rates in mind.
            </p>
          </div>
        </div>
        <div className={`mt-5 ${mounted? 'animate_stats' : ''}`}>

            <h1 className="text-2xl font-semibold text_primary mt-5 lg:font32px md:text-2xl">Rate Calculator</h1>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base ">
              Easily estimate the current exchange rate between cryptocurrency
              and naira with our rate calculator. Whether you're buying or
              selling get real-time conversion rates to make informed decisions
              about your cryptocurrency transactions.
            </p>
          <RateCalculator/>
        </div>
      </section>

      <Faq/>

      <Footer />
    </>
  );
}
