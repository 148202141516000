import Accordian from "./Accordian";

export default function QandA() {
  return (
    <>
      <section className="sectionFive lg:lg_screenp md:md_screenp">
        <h1 className="text-2xl font-semibold text_primary lg:font32px">
          Get Clarity: Frequently Asked Questions
        </h1>
        <p className="text-lg text_primary mt-5 lg:text-lg lg:w-10/12 md:text-lg">
        Find answers to common questions about our services, security measures and customer support get clarity on how Tradegenie works
        </p>
        <div className="grid mt-5 lg:grid-cols-1 lg:mt-5 ">
          <Accordian
            title="Is Tradegenie secure for cryptocurrency transactions?"
            body="The security of our users is out priority. Trade confidently with Tradegenie Peer-to-Peer."
          />
          <Accordian
            title="What support options are available if I encounter any issues or have questions about Tradegenie?"
            body="At Tradegenie, we provide extensive customer service to assist you every step of the way. You can contact our committed support team via phone, email, or live chat if you have any problems or inquiries concerning our platform. Our experienced customer service agents are here to quickly resolve your issues and guarantee a positive user experience."
          />

          <Accordian
            title="How does Tradegenie ensure swift and reliable international payments?"
            body="Tradegenie is dedicated to helping companies of all sizes make easy international payments. To guarantee quick and dependable transactions anywhere in the world, we collaborate with reputable partners and leverage blockchain / crypto currency to carry out these transactions."
          />
        </div>
      </section>
    </>
  );
}
