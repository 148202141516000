import { FaAngleDown, FaDollarSign } from "react-icons/fa";
import { IoSwapVerticalSharp } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";
import { CgArrowsExchangeV } from "react-icons/cg";

export default function RateCalculator() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [cryptoValue, setCryptoValue] = useState(null);
  const [baseCurrency, setBaseCurrency] = useState(null);
  const [cryptos, setCryptos] = useState([]);
  const [cryptoAmount, setCryptoAmount] = useState("");
  const [currencyAmount, setCurrencyAmount] = useState("");
  const [dollarAmount, setDollarAmount] = useState("");
  const [activeButton, setActiveButton] = useState("Buy");
  const [currencies,setCurrencies]=useState()

  useEffect(() => {
    fetchCryptos();
    fetchCurrencies()
  }, []);

  const fetchCurrencies= async()=>{
    try {
      const res = await axios.get("https://api.usetradegenie.com/currency/")
      console.log(res.data.data)
      setCurrencies(res.data.data)
    } catch (error) {
      
    }
  }

  const fetchCryptos = async () => {
    try {
      const res = await axios.get(
        "https://min-api.cryptocompare.com/data/top/mktcapfull",
        {
          params: {
            limit: 100,
            tsym: "USD",
            api_key: process.env.APIKEY,
          },
        }
      );
      const cryptoData = res.data.Data.map((item) => ({
        id: item.CoinInfo.Id,
        name: item.CoinInfo.FullName,
        symbol: item.CoinInfo.Name,
        price: item.DISPLAY?.USD?.PRICE,
        icon: `https://www.cryptocompare.com${item.CoinInfo.ImageUrl}`,
      }));
      setCryptos(cryptoData);
    } catch (error) {
      console.error("Error fetching cryptocurrencies:", error);
    }
  };



  const fetchExchangeRate = async (fromSymbol, toSymbol) => {
    try {
      const res = await axios.get(
        `https://min-api.cryptocompare.com/data/price?fsym=${fromSymbol}&tsyms=${toSymbol}&api_key=${process.env.APIKEY}`
      );
      return res.data[toSymbol];
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
      return null;
    }
  };

  const handleCryptoAmountChange = async (e) => {
    const value = e.target.value;
    setCryptoAmount(value);

    if (cryptoValue) {
      const rate = await fetchExchangeRate(cryptoValue.symbol, "USD");
      if (rate) {
        setDollarAmount((value * rate).toFixed(2));
      }
      if (baseCurrency) {
        const currencyRate = activeButton === "Buy" ? baseCurrency.buyrate : baseCurrency.sellrate;
        if (currencyRate) {
          setCurrencyAmount((value * rate * currencyRate).toFixed(2));
        }
      }
    }
  };

  const handleCurrencyAmountChange = async (e) => {
    const value = e.target.value;
    console.log("value",value)
    setCurrencyAmount(value);

    if (cryptoValue && baseCurrency) {
      const rate =
        activeButton === "Buy"
          ? baseCurrency.buyrate
          : baseCurrency.sellrate;
      if (rate) {
        console.log("currency",rate)
        const cryptoRate = (value / rate)
        const cryptoPrice = parseFloat(cryptoValue.price.replace(/[^0-9.-]+/g, ""));
        console.log(cryptoPrice)
        console.log("pre-result",cryptoRate)
        const cryptoRate2  =    cryptoRate / cryptoPrice
        console.log("final rate",cryptoRate2)
        // console.log("crypro rate",cryptoValue.price)
        setCryptoAmount((cryptoRate2).toFixed(6));
      }
    }

    const rateToUSD =
      activeButton === "Buy"
        ? baseCurrency?.buyrate
        : baseCurrency?.sellrate;
    if (rateToUSD) {
      setDollarAmount((value / rateToUSD).toFixed(2));
    }
  };

  const handleDollarAmountChange = async (e) => {
    const value = e.target.value;
    console.log("dollar amount",value)
    setDollarAmount(value);

    if (cryptoValue) {
      console.log(cryptoValue.symbol);
      const rate = await fetchExchangeRate("USD", cryptoValue.symbol);
      console.log(rate);
      if (rate) {
        setCryptoAmount((rate * value).toFixed(6));
      }
    }

    if (baseCurrency) {
      const rate =
        activeButton === "Buy"
          ? baseCurrency?.buyrate
          : baseCurrency?.sellrate;
      if (rate) {
        setCurrencyAmount((value * rate));
      }
    }
  };

  const ModalFunc = () => {
    setModalOpen(!modalOpen);
  };

  const ModalFunc2 = () => {
    setModalOpen2(!modalOpen2);
  };

  const getCryptoValue = (name, icon, symbol, price) => {
    setCryptoValue({ name, icon, symbol, price });
    setModalOpen(false);
  };

  const getBaseCurrency = (name, sellrate,buyrate) => {
    setBaseCurrency({ name, sellrate,buyrate });
    setModalOpen2(false);
  };



  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const generateWhatsAppLink = () => {
    const action = activeButton === "Buy" ? "Buying" : "Selling";
    if(action === "Buying"){
      const message = `Trade Request\n\nAction: ${action}\n\nI have:${currencyAmount} ${baseCurrency?.name}\nI need: ${cryptoValue?.symbol}\nAmount needed: ${cryptoAmount}`;
      return `https://wa.me/2348186289982?text=${encodeURIComponent(message)}`;
    }else{
    const message = `Trade Request\n\nAction: ${action}\n\nI have: ${cryptoAmount} ${cryptoValue?.symbol}\nI need: ${baseCurrency?.name}\nAmount needed: ${currencyAmount}`;
    return `https://wa.me/2348186289982?text=${encodeURIComponent(message)}`;
    }

  };

  return (
    <>
      <section className="cryptoCalc mt-5">
      <div className="flex gap-3 mb-3">
          <button
            className={`px-6 py-2 rounded-full ${
              activeButton === "Buy" ? "color_blue text-white" : "bg-gray-400 text-white"
            }`}
            onClick={() => handleButtonClick("Buy")}
          >
            Buy
          </button>
          <button
            className={`px-6 py-2 rounded-full ${
              activeButton === "Sell" ? "color_blue text-white" : "bg-gray-400 text-white"
            }`}
            onClick={() => handleButtonClick("Sell")}
          >
            Sell
          </button>
        </div>
        <div className="select_top justify-between p-3 flex items-center">
          <div className="flex gap-5 items-center">
            {cryptoValue?             <div className="image_cont text-2xl">
              <img src={cryptoValue?.icon} alt="" />
            </div> : ''}

            <div className="item_cont">
              <p className="cursor-pointer">
                {cryptoValue ? cryptoValue.name : "Select Crypto"}
              </p>
            </div>
          </div>
          <p className="text-green-800">{cryptoValue?.price}</p>
          <FaAngleDown className="cursor-pointer" onClick={ModalFunc} />
        </div>
        {modalOpen && (
          <div className="crypto_calc_dropdown mt-1">
            {cryptos.map((item) => (
              <div
                key={item.id}
                className="hover_option flex gap-5 p-5 items-center cursor-pointer"
                onClick={() =>
                  getCryptoValue(
                    item?.name,
                    item?.icon,
                    item?.symbol,
                    item?.price
                  )
                }
              >
                <div className="icon_cont text-2xl">
                  <img src={item.icon} alt="" />
                </div>
                <div className="item_cont">
                  <p className="text-lg">{item?.name}</p>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="crypto_inpt_cont flex  justify-between w-full border">
          <div className="flex w-full ">
            <input
              type="text"
              name="cryptoAmount"
              placeholder="Enter Crypto Amount"
              value={cryptoAmount}
              onChange={handleCryptoAmountChange}
              className="w-full"
            />
          </div>
          <div className="flex w-full ">
            <p className="calc_inpt_style">
              <FaDollarSign />
            </p>
            <input
              type="text"
              name="dollarAmount"
              placeholder="Amount in $"
              value={dollarAmount}
              onChange={handleDollarAmountChange}
              className="w-full"
            />
          </div>
        </div>
      </section>
      <div className="p-3">
        <CgArrowsExchangeV className="swap_icon" />
      </div>
      <section className="cryptoCalc">
        <div className="select_top justify-between p-3 flex items-center">
          <div className="items-center">
            <div className="item_cont">
              <p className="cursor-pointer">
                {baseCurrency ? baseCurrency.name : "Select Currency"}
              </p>
            </div>
          </div>
          <FaAngleDown className="cursor-pointer" onClick={ModalFunc2} />
        </div>
        {modalOpen2 && (
          <div className="crypto_calc_dropdown modal-exit-active mt-1">
            {currencies.map((item) => (
              <div
                key={item.symbol}
                className="hover_option cursor-pointer p-5"
                onClick={() => getBaseCurrency(item.name, item.sellrate,item.buyrate)}
              >
                <p className="text-lg">{item.symbol} - {item.name}</p>
              </div>
            ))}
          </div>
        )}
        <div className="crypto_inpt_cont w-full border">
          <input
            type="text"
            name="currencyAmount"
            placeholder="Enter Amount"
            value={currencyAmount}
            onChange={handleCurrencyAmountChange}
            className="w-full"
          />
        </div>
        <div className="flex justify-between items-center">
          <a  href={generateWhatsAppLink()}
                target="_blank"
                rel="noopener noreferrer" className="w-full">
          <button className="rate_calc_btn w-full lg:width60 mt-5 whitespace-nowrap hover_btn">
            Proceed to Trade
          </button>
          </a>

        </div>
      </section>
    </>
  );
}
