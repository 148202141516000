import logo from "./../assets/images/f3ed2a2461e69b88d7e92b65fc9d8aa0 (1).png";
import { LuTally1 } from "react-icons/lu";
import { FaInstagram, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import { BiPhone } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import { Spinner } from "@chakra-ui/react";
import "sweetalert2/src/sweetalert2.scss";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const validateEmail = (email) => {
    if (email.trim() === "") {
     return setErrMessage("Email is required");

    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
       return setErrMessage("invalid email address");
      } else {
        setErrMessage("");
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(email);
  };

  const handleSubmit = async () => {
    validateEmail(email);
    try {
      setLoading(true);
      const res = await fetch("https://api.usetradegenie.com/addsubscriber", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await res.json();
      if (data.error) {
        setErrMsg(data.error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error.title,
        });
        setErrMessage("");
        setEmail("")
        return;
      }
      console.log(data);
      Swal.fire({
        title: "Success!",
        text: "Thank you for subscribing!",
        icon: "success",
      });
      setErrMessage("");
      setEmail("")
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="footer_style bg_color pb-12 lg:pb-0">
        <h1 className="text-white text-2xl">Subscribe to Our Newsletter</h1>
        <p className="text-white text-lg mt-5 lg:w-11/12">
          Stay in the Loop! Sign up for our newsletter to receive the latest
          updates, news, and special offers straight to your inbox and be the
          first to know about our app launching soon!
        </p>

        <div className="flex flex-col  md:flex-col lg:flex-row items-center w-full mt-16 md:mt-8 gap-5">
          <div className="email_inpt_div w-full lg:w-4/5">
            <input
              className="email_inpt w-full"
              placeholder="Enter your email address"
              type="text"
              value={email}
              onChange={(e) => handleEmailChange(e)}
            />
          </div>

          <div className="email_inpt_div2 w-full lg:w-1/5">
            <button
              className="footer_btn w-full whitespace-nowrap hover_btn"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Subscribe"}
            </button>
          </div>
        </div>
        <p className="text-red-600 mt-3">{errMessage}</p>

        <div className="lg:grid-cols-3 grid md:grid-cols-1 grid-cols-1 justifycenter_tablet  lg:mt-10 items-center w-full ">
          <div className=" flex justify-center lg:justify-start ">
            <img src={logo} className="footer_logo" alt="" />
          </div>

          <div className="mobile_footer_ul text-white flex flex-col lg:flex-row items-center md:justify-center gap-5 whitespace-nowrap">
            <div className="flex items-start gap-8">
              <Link to="/">
                <p>Home </p>
              </Link>

              <Link to="/services">
                <p>Services </p>
              </Link>
              <Link to="/about">
                <p>About </p>
              </Link>
              <Link to="/contact">
                <p>Contact Us </p>
              </Link>
            </div>

            <div className="flex items-center gap-8">
              <LuTally1 className="footer_line text-xl" />
              <Link
                to="https://www.instagram.com/tradegenie_?igsh=MWNzazJyZWQ1c3Z2eg%3D%3D&utm_source=qr"
                target="_blank"
              >
                <FaInstagram className="text-xl hover_primary" />
              </Link>
              <a
                href="https://wa.me/2348186289982"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp className="text-xl hover_primary" />
              </a>

              <FaLinkedin className="text-xl hover_primary" />
            </div>
          </div>

          <div className="lg:text-right mt_10px lg:mt-0 text-center mt-5 ">
            <p className="copyright text-white">© Copyright 2024 Tradegenie</p>
          </div>
        </div>
      </section>
    </>
  );
}
