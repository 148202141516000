import Navigation from "../components/Navigation";
import Image from "./../assets/images/3bf66375004840b826cbe41eb3b3ce2a.png";
import Image0 from "./../assets/images/249f51be08006715d9c2183316f9b3e2.png";
import Image1 from "./../assets/images/7d6522e87109deca08bff9d782625fe5.png";
import ReviewCard from "../components/ReviewCard";
import Footer from "../components/Footer";
import Faq from "../components/Faq";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";

import RateCalculator from "../components/RateCalculator";

export default function Home() {
  const [mounted, setMounted] = useState(false);

  const volumeRef = useRef(null);
  const successRef = useRef(null);
  const transactionsRef = useRef(null);
  const rateCalculatorRef = useRef(null);

  useEffect(() => {
    animateCount(volumeRef.current, 14, 3000, 'k+ Volume');
    animateCount(successRef.current, 98, 3000, '%+ Success Rate');
    animateCount(transactionsRef.current, 20, 3000, 'k+ Transactions');
  }, []);

  const animateCount = (element, end, duration, suffix = "+") => {
    let start = 1;
    let range = end - start;
    let startTime = null;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      let progress = timestamp - startTime;
      let percent = Math.min(progress / duration, 1);
      let currentNumber = Math.floor(percent * range + start);
      element.textContent = currentNumber.toLocaleString() + suffix;
      if (progress < duration) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  };

  const handleGetStartedClick = () => {
    if (rateCalculatorRef.current) {
      rateCalculatorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <Navigation onGetStartedClick={handleGetStartedClick}/>
      <div className="world_banner lg:lg_screenp">
        <h1
          className={`text-xl font-semibold w-full pt-16  text-white lg:text-6xl lg:lineh85px md:text-4xl md:lineh60px ${mounted ? "animate_from_left" : "hidden"}`}
        >
          Unlock the World of Cryptocurrency with Tradegenie
        </h1>
        <p
          className={`w-full mt-8 text-white text-lg lg:text-lg lg:w-9/12 lg:mt-8 md:text-base ${mounted ? "animate_stats" : "hidden"}`}
        >
          Welcome to Tradegenie, your gateway to the world of cryptocurrencies.
          We provide seamless transactions, secure peer-to-peer trading and
          hassle-free cross-border payments. Explore the exciting world of
          digital assets with confidence
        </p>
        <button
          className={`world_banner_btn mt-10 lg:w-4/12 w-full md:w-5/12 whitespace-nowrap hover_btn ${mounted ? "animate_from_left" : "hidden"}`}
          onClick={handleGetStartedClick}>
          Get Started
        </button>

        <div
          className={`world_stats md:w-2/4 md:items-center lg:items-center hidden lg:flex lg:mt-60 md:flex md:mt-20 lg:w-2/4 lg:text-2xl  gap-5 lg:p-5 font-semibold whitespace-nowrap ${mounted ? "animate_stats" : ""}`}
        >
      <p ref={volumeRef} className="count-up">1k+ Volume</p>
      <p ref={successRef} className="count-up">1%+ Success Rate</p>
      <p ref={transactionsRef} className="count-up">1k+ Transactions</p> 

        </div>
      </div>

      <section className="sectionOne grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:lg_screenp md:md_screenp">
        <div className={`lg:order-first order-last mt-10 lg:mt-0 md:mt-0`}>
          <h1
            className={`text-2xl font-semibold text_primary lg:font32px   ${mounted ? "animate_from_left" : ""}`}
          >
            Discover Our Story: About Tradegenie
          </h1>
          <p
            className={`text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base  ${mounted ? "animate_from_left" : ""}`}
          >
            Tradegenie is a peer-to peer platform that facilitates buying and
            selling of cryptocurrency. Tradegenie is committed to making
            cryptocurrency more accessible worldwide. Tradegenie makes it easy
            and securee for users to exchange digital assets.
          </p>
          <p
            className={`text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base  ${mounted ? "animate_from_left" : ""}`}
          >
            With a commitment to swift transactions, short dispute resolution
            time and T+0 international payments, Tradegenie is dedicated to
            providing users with a trusted gateway to the world of
            cryptocurrency.
          </p>
          <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base ">
            {" "}
            Businesses and corporations can also rely on our deep liquidity for
            instant settlements and procurement around the world.
          </p>

          <Link to="/about">
            <button className="world_banner_btn2 w-full mt-10 lg:width60 md:mt-5 lg:mt-10 whitespace-nowrap hover_btn">
              Read More
            </button>
          </Link>
        </div>

        <div className="lg:imgCont1 order-first md:image_msize2 md:flex md:items-center">
          <img
            src={Image}
            className={`img200px image_msize2 lg:img483px lg:m-0 md:width332px md:m-0 hover:animate-bounce ${mounted ? "animate_stats" : ""}`}
            alt=""
          />
        </div>
      </section>
      <section className="sectionTwo grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:lg_screenp md:md_screenp">
        <div className={`md:image_msize2 md:flex md:items-center `}>
          <img
            src={Image0}
            className={`img200px image_msize2 lg:img483px lg:m-0 md:width332px md:m-0 hover:animate-bounce ${mounted ? "animate_from_left" : ""}`}
            alt=""
          />
        </div>
        <div
          className={`mt-10 lg:mt-0 md:mt-0  ${mounted ? "animate_stats" : ""}`}
        >
          <h1
            className={`text_primary text-2xl font-semibold lg:font32px lg:lineh42px`}
          >
            Unlock the Power of Digital Finance with Tradegenie
          </h1>
          <div>
            <h3 className="service_type_title text_primary mt-5">
              Peer-To-Peer Cryptocurrency Exchange
            </h3>
            <p className="text-lg text_primary font-light mt-5 lg:service_type_text md:text-lg md:mt-3">
              Never run out of fiat currency or cryptocurrency. Buying and sell
              cryptocurrency using Tradegenie peer-to-peer network
            </p>
          </div>

          <div>
            <h3 className="service_type_title text_primary mt-5">
              International Payments
            </h3>
            <p className="text-lg text_primary font-light mt-5 lg:service_type_text md:text-lg md:mt-3">
              Take advantage of our deep liquidity for international
              settlements. Make instant procurements for your business worldwide
              and manage your foreign exchange risk by taking advantage of
              our deep liquidity.
            </p>
          </div>

          <Link to="/services">
            <button className="world_banner_Sbtn w-full mt-10 lg:width60 md:mt-5 lg:mt-10 whitespace-nowrap hover_btn">
              Explore Services
            </button>
          </Link>
        </div>
      </section>
      <section className="sectionThree lg:lg_screenp md:md_screenp">
        <div>
          <h1 className="text-2xl font-semibold text_primary lg:font32px">
            What Our Users Are Saying
          </h1>
          <p className="w-full mt-5 text-lg text_primary lg:text-lg lg:w-10/12 lg:mt-8">
            Explore firsthand experiences from our satisfied users as they share
            their journey with Tradegenie. Discover how our platform has
            transformed their cryptocurrency transactions and cross-border
            payments, providing security and speed.
          </p>
        </div>
        <div className="">
          <ReviewCard />
        </div>
      </section>

      <section className="sectionFour grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 lg:lg_screenp md:md_screenp">
        <div className={`lg:imgCont2 `}>
          <img
            src={Image1}
            alt=""
            className={`img200px image_msize2 lg:img483px lg:m-0 md:width332px md:m-0 hover:animate-bounce ${mounted ? "animate_from_left" : ""}`}
          />
        </div>
        <div ref={rateCalculatorRef}  className={` ${mounted ? "animate_stats" : ""}`}>
          <h1 className="`text-2xl font-semibold text_primary lg:font32px">
            Rate Calculator
          </h1>
          <p className="mt-5 lg:mt-8 text-lg text_primary lg:text-lg">
            Easily estimate the current exchange rate between cryptocurrency and
            Naira with our rate calculator. Whether you're buying or selling get
            real-time conversion rates to make informed decisions about your
            cryptocurrency transactions.
          </p>
          <RateCalculator />
        </div>
      </section>
      <Faq />

      <Footer />
    </>
  );
}
