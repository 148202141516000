import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";

function Accordian(props) {
  return (
    <Accordion  allowMultiple className="mt-3">
      <AccordionItem pt={1} className="mobile_accordian" border="none" mt={0}>
        <h2>
          <AccordionButton
            className="mobile_accordian bg_color text-white lg:p-5"
            _hover={{ bg: "bg_primary" }}
          >
            <Box
              as="span"
              flex="1"
              className="mobile_accordian2 text-lg"
              textAlign="left"
              p={2}
            >
              {props.title}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel
          pb={5}
          className="mobile_accordian2 bg_color text-white text-lg"
          p={5}
          pt={10}

        >
          {props.body}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default Accordian;
