import { FaRegStar } from "react-icons/fa";

export default function ReviewCard() {
  return (
    <div className="grid lg:grid-cols-3 mt-14 gap-5 sm:grid-cols-1 md:grid-cols-2">
      <div>
        <div className="flex items-center gap-1">
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
        </div>
        <p className="mt-6 text_primary lg:text-base">
          I've been using Tradegenie for my cross-border payments, and I'm
          impressed by how fast and seamless the transactions are. It's a
          game-changer!
        </p>
        <h1 className="text-xl font-normal mt-6 text_primary">Sarah Agbo</h1>
      </div>
      <div>
        <div className="flex items-center gap-1">
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
        </div>
        <p className="mt-6 text_primary lg:text-base">
        The way that I handle peer-to-peer payments has significantly improved thanks to Tradegenie. It's an easy, trustworthy, and efficient method. My financial processes are now much more manageable thanks to this incredible technology.

        </p>
        <h1 className="text-xl font-normal mt-6 text_primary">Micheal Abiola</h1>
      </div>
      <div>
        <div className="flex items-center gap-1">
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
          <FaRegStar className="rating_icon" />
        </div>
        <p className="mt-6 text_primary lg:text-base">
        Tradegenie has transformed my peer-to-peer exchanges. The platform does away with the typical annoyances and delays by being user-friendly, quick, and secure. It's a dependable P2P payment management system, and I heartily endorse it to anyone looking for a productive approach to manage transactions.

        </p>
        <h1 className="text-xl font-normal mt-6 text_primary">Patrick James</h1>
      </div>
    </div>
  );
}
