import Navigation from "../components/Navigation";
import "./../styles/Contact.css";
import { FaInstagram, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import Faq from "./../components/Faq";
import Footer from "../components/Footer";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Contact() {
  const [mounted, setMounted] = useState(false);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    phone: "",
    email: "",
    message: "",
    interest: "",
  });
  const [err, setErr] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  const handleInputValid = () => {
    const { fullName, phone, email, message, interest } = userDetails;
    if (!fullName || !phone || !email || !message || !interest) {
      setErr(true);
      return
    }
    sendEmail();
  };

  const handleInputValues = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };


  const sendEmail = () => {
    const { fullName, phone, email, message, interest } = userDetails;
    const mailtoLink = `mailto:Israel@usetradegenie.com?subject=Contact Form Submission&body=Full Name: ${fullName}%0APhone: ${phone}%0AEmail: ${email}%0AInterest: ${interest}%0AMessage: ${message}`;
    const hiddenLink = document.createElement('a');
    hiddenLink.href = mailtoLink;
    hiddenLink.click();
  };


  console.log(userDetails);

  return (
    <>
      <Navigation />
      <section className="lg:lg_screenp md:md_screenp CsectionOne">
        <div
          className={`flex gap-10 w-full items-center pt-16 ${mounted ? "animate_from_left" : ""}`}
        >
          <h1 className="text-2xl font-semibold text_primary  lg:font32px md:text-2xl whitespace-nowrap">
            {" "}
            Contact Us
          </h1>
          <hr className="header_hr" />
        </div>
        <p
          className={`text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base ${mounted ? "animate_stats" : ""}`}
        >
          Get in Touch: Reach Out to Tradegenie Today
        </p>
      </section>
      <section className="CsectionTwo grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 lg:lg_screenp md:md_screenp">
        <div className={`${mounted ? "animate_from_left" : ""}`}>
          <h1 className="CsectionTwo_title">Send a Message</h1>
          <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
            Drop us a line! use the form below to send us your questions or
            feedback.
          </p>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 items-center gap-5 mt-8">
            <div className="form ">
              {!userDetails.fullName && err && (
                <span className="err_msg">please enter your fullname</span>
              )}
              <input
                type="text"
                name="fullName"
                className="form_input"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleInputValues(e)}
              />

              <label htmlFor="email" className="form_label">
                Full Name
              </label>
            </div>

            <div className="form">
              {!userDetails.interest && err && (
                <span className="err_msg">please enter your interest</span>
              )}
              <input
                type="text"
                name="interest"
                className="form_input"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleInputValues(e)}
              />
              <label htmlFor="email" className="form_label">
                Interest In
              </label>
            </div>
            <div className="form ">
              {!userDetails.phone && err && (
                <span className="err_msg">please enter your phone</span>
              )}
              <input
                type="text"
                name="phone"
                className="form_input"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleInputValues(e)}
              />
              <label htmlFor="email" className="form_label">
                Phone Number
              </label>
            </div>
            <div className="form">
              {!userDetails.email && err && (
                <span className="err_msg">please enter your email</span>
              )}
              <input
                type="text"
                className="form_input"
                name="email"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleInputValues(e)}
              />
              <label htmlFor="email" className="form_label">
                Email
              </label>
            </div>
          </div>

          <div className="mt-10">
            
            <div className="form2">
            {!userDetails.message && err && (
                <span className="err_msg mt-12">please enter your message</span>
              )}
              <textarea
                type="text"
                className="form_input2"
                name="message"
                autoComplete="off"
                placeholder=" "
                onChange={(e) => handleInputValues(e)}
              />
              <label htmlFor="email" className="form_label">
                Message
              </label>
              
            </div>
            
          </div>

          <div>
            <button
              className="world_banner_btn2 w-full lg:mt-10 mt-10 lg:width60 md:mt-5 hover_btn"
              onClick={() => handleInputValid()}
            >
              Submit
            </button>
          </div>
        </div>

        <div className={`max-sm:mt-5 ${mounted ? "animate_stats" : ""}`}>
          <div>
            <h1 className="CsectionTwo_title">Connect with Us on Instagram!</h1>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
              Stay updated! follow us on Instagram for news, updates, and
              behind-the-scenes insights.
            </p>
            <Link to="https://www.instagram.com/tradegenie_?igsh=MWNzazJyZWQ1c3Z2eg%3D%3D&utm_source=qr">
              <FaInstagram className="socials_icon mt-5 hover_primary" />
            </Link>
          </div>

          <div className="mt-8">
            <h1 className="CsectionTwo_title">Connect with Us on Whatsapp!</h1>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
              Connect instantly! chat with us on WhatsApp for quick assistance
              and support.
            </p>

            <FaWhatsapp className="socials_icon mt-5 hover_primary" />
          </div>

          <div className="mt-8">
            <h1 className="CsectionTwo_title">Call Us!</h1>
            <p className="text_primary  text-lg md:mt-5 mt-5 lg:text-lg md:text-base">
              Connect instantly! chat with us on WhatsApp for quick assistance
              and support.
            </p>
            <Link to="" target="_blank">
              <FaLinkedin className="socials_icon mt-5 hover_primary" />
            </Link>
          </div>
        </div>
      </section>
      <Faq />
      <Footer />
    </>
  );
}
