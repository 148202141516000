import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";
import logo from "./../assets/images/f3ed2a2461e69b88d7e92b65fc9d8aa0.png";

export default function Navigation({ onGetStartedClick }) {
  const [open, setOpen] = useState(false);

  if (open) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <div className="navbar_mobile relative">
      <div className=" bg_color h-auto w-full flex p-3  fixed top-0 z-50 ">
        <div className="lg:flex lg:justify-between flex w-full justify-between lg:w-full ">
          <div className="flex items-center ">
            <Link to="/" className="absolute ">
              <img src={logo} className="header_img " alt="LOGO" />
            </Link>
          </div>
          <div className="navbar_items flex items-center gap-14">
            <ul className="navbar_items_ul flex gap-10">
              <Link to="/">
                <li className="text-white"> HOME</li>
              </Link>
              <Link to="/services">
                <li className="text-white">SERVICE</li>
              </Link>

              <Link to="/about">
                <li className="text-white">ABOUT US</li>
              </Link>
              <Link to="/contact">
                <li className="text-white">CONTACT US</li>
              </Link>
            </ul>
            <div className="">
              <button className="header_btn hover_btn" onClick={onGetStartedClick}>Get Started</button>
              <FaBars className="menu_icon" onClick={() => setOpen(true)} />
            </div>
          </div>
        </div>

        {open ? (
          <div className="modal">
            <div className="overlay">
              <div className="Mobile_dropdown">
                <ul>
                  <Link to="/">
                    <li className="text-white"> HOME</li>
                  </Link>
                  <Link to="/services">
                    <li className="text-white mt-5">SERVICE</li>
                  </Link>

                  <Link to="/about">
                    <li className="text-white mt-5">ABOUT US</li>
                  </Link>
                  <Link to="/contact">
                    <li className="text-white mt-5">CONTACT US</li>
                  </Link>
                </ul>
                <FaTimes
                  className="cancle_icon"
                  onClick={() => setOpen(false)}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
